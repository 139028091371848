import React, {useState, useEffect} from 'react';
import Slider from "react-slick";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import { useSelector } from 'react-redux';

const WalletSlide = () => {
  const user = useSelector((state) => state.user.user);
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
  const fundingBal = useSelector((state) => state.wallet.fundingBalance);
  const subBal = useSelector((state) => state.wallet.subscriptionBalance);
  const refBal = useSelector((state) => state.wallet.referralBalance);
  const mainBal = useSelector((state) => state.wallet.withdrawableBalance);
 
  
    const settings = {
        autoplay: true,
        pauseOnHover: true,
        dots: false,
        infinite: true,
        autoplaySpeed: 3000,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
     
      
  return (
    <div>
        <Card sx={{marginTop: '75px', paddingX: '10px'}} elevation={0}>
      <Slider {...settings}>
        <div>
            <Card sx={{marginX: '5px', borderRadius: '10px'}}>
                <Typography px={2} mb={-5} py={1}  sx={{display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgba(6, 6, 6, 0.14)', fontWeight: 'bold', fontSize: 16, position: 'relative', color: '#fff'}} gutterBottom variant="subtitle2" component="div">
                    
                    <Typography sx={{color: '#fff', fontSize: 18}}  variant="h5" color="text.secondary">
                        Funding Wallet
                    </Typography>

                    <Avatar sx={{ width: 24, height: 24 }}>
                    &#36;
                    </Avatar>
                </Typography>
                <CardMedia
                        component="img"
                        sx={{ width: '100%', height: 200 }}
                        image="https://res.cloudinary.com/dg8pxsdme/image/upload/v1701238696/amdcard_ghnuzf.png"
                        alt="wallet"
                    />
                    <Typography mb={-6} sx={{color: '#000000', textAlign: 'left', fontWeight: 'bold', transform: 'translate(0px, -120px)', bgcolor: '#fff', boxShadow: 2, width: 'fit-content', paddingY: '5px', paddingX: '9px', fontSize: 24}}  variant="h5" color="primary">
                   {formatPrice(fundingBal)}
                    </Typography>

            </Card>
        </div>

        <div>
            <Card sx={{marginX: '5px', borderRadius: '10px'}}>
                <Typography px={2} mb={-5} py={1}  sx={{display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgba(6, 6, 6, 0.14)', fontWeight: 'bold', fontSize: 16, position: 'relative', color: '#fff'}} gutterBottom variant="subtitle2" component="div">
                    
                    <Typography sx={{color: '#fff', fontSize: 18}}  variant="h5" color="text.secondary">
                        Account Blance
                    </Typography>

                    <Avatar sx={{ width: 24, height: 24 }}>
                    &#36;
                    </Avatar>
                </Typography>
                <CardMedia
                        component="img"
                        sx={{ width: '100%', height: 200 }}
                        image="https://res.cloudinary.com/dg8pxsdme/image/upload/v1701238696/amdcard_ghnuzf.png"
                        alt="wallet"
                    />
                    <Typography mb={-6} sx={{color: '#000000', textAlign: 'left', fontWeight: 'bold', transform: 'translate(0px, -120px)', bgcolor: '#fff', boxShadow: 2, width: 'fit-content', paddingY: '5px', paddingX: '9px', fontSize: 24}}  variant="h5" color="primary">
                   {formatPrice(mainBal)}
                    </Typography>

            </Card>
        </div>



        <div>
        <Card sx={{marginX: '5px', borderRadius: '10px'}}>
                <Typography px={2} mb={-5} py={1}  sx={{display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgba(6, 6, 6, 0.14)', fontWeight: 'bold', fontSize: 16, position: 'relative', color: '#fff'}} gutterBottom variant="subtitle2" component="div">
                    
                    <Typography sx={{color: '#fff', fontSize: 18}}  variant="h5" color="text.secondary">
                        Investment Wallet
                    </Typography>

                    <Avatar sx={{ width: 24, height: 24 }}>
                    &#36;
                    </Avatar>
                </Typography>
                <CardMedia
                        component="img"
                        sx={{ width: '100%', height: 200 }}
                        image="https://res.cloudinary.com/dg8pxsdme/image/upload/v1701238696/amdcard_ghnuzf.png"
                        alt="wallet"
                    />
                    <Typography mb={-6} sx={{color: '#000000', textAlign: 'left', fontWeight: 'bold', transform: 'translate(0px, -120px)', bgcolor: '#fff', boxShadow: 2, width: 'fit-content', paddingY: '5px', paddingX: '9px', fontSize: 24}}  variant="h5" color="primary">
                    {formatPrice(subBal)}
                    </Typography>

            </Card>
        </div>


        <div>
        <Card sx={{marginX: '5px', borderRadius: '10px'}}>
                <Typography px={2} mb={-5} py={1}  sx={{display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgba(6, 6, 6, 0.14)', fontWeight: 'bold', fontSize: 16, position: 'relative', color: '#fff'}} gutterBottom variant="subtitle2" component="div">
                    
                    <Typography sx={{color: '#fff', fontSize: 18}}  variant="h5" color="text.secondary">
                        Referral Wallet
                    </Typography>

                    <Avatar sx={{ width: 24, height: 24 }}>
                    &#36;
                    </Avatar>
                </Typography>
                <CardMedia
                        component="img"
                        sx={{ width: '100%', height: 200 }}
                        image="https://res.cloudinary.com/dg8pxsdme/image/upload/v1701238696/amdcard_ghnuzf.png"
                        alt="wallet"
                    />
                    <Typography mb={-6} sx={{color: '#000000', textAlign: 'left', fontWeight: 'bold', transform: 'translate(0px, -120px)', bgcolor: '#fff', boxShadow: 2, width: 'fit-content', paddingY: '5px', paddingX: '9px', fontSize: 24}}  variant="h5" color="primary">
                    {formatPrice(refBal)}
                    </Typography>

            </Card>
        </div>
      </Slider>
      </Card>
    </div>
  )
}

export default WalletSlide
