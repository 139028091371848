import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { setInviter } from '../../request/Referrals/referalSlice';
import { useDispatch } from 'react-redux';

const Invitation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        let inviter = (new URLSearchParams(window.location.search)).get("referral");
        if (inviter !== '') {
          document.title = 'INVITATION FROM '+ inviter;
            dispatch(setInviter(inviter));
            // localStorage.setItem('invited-by', inviter);
            setTimeout(() => {
                // navigate('/register');
                return window.location.href= 'https://capitals-vista.com';
            }, 500);
        }
    })
  return (
    <div></div>
  )
}

export default Invitation